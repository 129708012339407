var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrap phone-robot"},[_c('div',{staticClass:"top-bar"},[_c('mtop')],1),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v("大模型应用")]),_c('div',{staticClass:"desc"},[_vm._v("基于善达达摩大模型能力深入企业级应用场景，提供多种大模型应用可落地方案，有效解决大模型幻觉、过拟合等问题，赋能千行百业，更注重特定领域内的深入理解和精准应用。")]),_c('div',{staticClass:"btn",on:{"click":function($event){_vm.dialogVisible = true, _vm.clickMenu('免费试用')}}},[_vm._v("免费试用")])])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('partner'),_c('fotterBanner'),_c('mbottom'),_c('demonstrate',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"closeDialog":function($event){_vm.dialogVisible = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-white dm-kf"},[_c('div',{staticClass:"title"},[_vm._v("达摩+智能客服")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-dm-kf"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section dm-zk"},[_c('div',{staticClass:"title"},[_vm._v("达摩+语音智控")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"detail-dm-zk"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"text-content"},[_vm._v("基于大模型的语音智控系统，融合达摩私有化大模型能力，实现流畅语音交互。它集信息查询、日程管理、多模态生成、硬件控制等功能于一体，提升生活工作效率。个性化推荐、内容生成及多设备协同，满足多样需求。不仅助力日常任务，更带来便捷、高效、个性化的智能生活工作体验，更是现代生活的得力助手。")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"bg-dm-zk"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-white rag"},[_c('div',{staticClass:"title"},[_vm._v("RAG智能知识库")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-rag"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section ai-agent"},[_c('div',{staticClass:"title"},[_vm._v("AI Agent")]),_c('div',{staticClass:"sub-title"},[_vm._v("Agent （智能体）是一种能够多感知环境、进行决策和执行动作的智能实体。不同于传统的人工智能，Agent具备通过主动思考、调用工具去逐步完成给定目标的能力。")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-ai-agent"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-white dm-plat"},[_c('div',{staticClass:"title"},[_vm._v("达摩平台：自研可视化工作流排编平台")]),_c('div',{staticClass:"sub-title"},[_vm._v("提供从 Agent 构建到 Al workflow 编排、RAG 检索、模型管理等能力，轻松构建和运营生成式 Al 原生应用。")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-dm-plat"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section case"},[_c('div',{staticClass:"title"},[_vm._v("通信行业RAG知识库案例")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-case-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-white case"},[_c('div',{staticClass:"title"},[_vm._v("金融行业Agent案例")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg-case-2"})])])
}]

export { render, staticRenderFns }