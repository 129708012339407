<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">大模型应用</div>
          <div class="desc">基于善达达摩大模型能力深入企业级应用场景，提供多种大模型应用可落地方案，有效解决大模型幻觉、过拟合等问题，赋能千行百业，更注重特定领域内的深入理解和精准应用。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 达摩+智能客服 -->
    <div class="section bg-white dm-kf">
      <div class="title">达摩+智能客服</div>
      <div class="content">
        <div class="bg-dm-kf"></div>
      </div>
    </div>

    <!-- 达摩+语音智控 -->
    <div class="section dm-zk">
      <div class="title">达摩+语音智控</div>
      <div class="content">
        <div class="detail-dm-zk">
          <div class="left">
            <div class="text-content">基于大模型的语音智控系统，融合达摩私有化大模型能力，实现流畅语音交互。它集信息查询、日程管理、多模态生成、硬件控制等功能于一体，提升生活工作效率。个性化推荐、内容生成及多设备协同，满足多样需求。不仅助力日常任务，更带来便捷、高效、个性化的智能生活工作体验，更是现代生活的得力助手。</div>
          </div>
          <div class="right">
            <div class="bg-dm-zk"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- RAG智能知识库 -->
    <div class="section bg-white rag">
      <div class="title">RAG智能知识库</div>
      <div class="content">
        <div class="bg-rag"></div>
      </div>
    </div>

    <!-- AI Agent -->
    <div class="section ai-agent">
      <div class="title">AI Agent</div>
      <div class="sub-title">Agent （智能体）是一种能够多感知环境、进行决策和执行动作的智能实体。不同于传统的人工智能，Agent具备通过主动思考、调用工具去逐步完成给定目标的能力。</div>
      <div class="content">
        <div class="bg-ai-agent"></div>
      </div>
    </div>

    <!-- 达摩平台：自研可视化工作流排编平台 -->
    <div class="section bg-white dm-plat">
      <div class="title">达摩平台：自研可视化工作流排编平台</div>
      <div class="sub-title">提供从 Agent 构建到 Al workflow 编排、RAG 检索、模型管理等能力，轻松构建和运营生成式 Al 原生应用。</div>
      <div class="content">
        <div class="bg-dm-plat"></div>
      </div>
    </div>

    <!-- 通信行业RAG知识库案例 -->
    <div class="section case">
      <div class="title">通信行业RAG知识库案例</div>
      <div class="content">
        <div class="bg-case-1"></div>
      </div>
    </div>

    <!-- 金融行业Agent案例 -->
    <div class="section bg-white case">
      <div class="title">金融行业Agent案例</div>
      <div class="content">
        <div class="bg-case-2"></div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      painList: [
        {
          name: '信息孤岛',
          icon: 'icon-pain-1.png'
        },
        {
          name: '信息篡改难杜绝',
          icon: 'icon-pain-2.png'
        },
        {
          name: '中心化存储',
          icon: 'icon-pain-3.png'
        },
        {
          name: '信息流通性差',
          icon: 'icon-pain-4.png'
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/agr-prod/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
